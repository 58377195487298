import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Header from "../src/component/header/header";
import Home from "../src/component/home/home";
// import Breadcrumb from "../src/component/header/breadCrumb";

import './App.css';
import Ourstory from "./component/about/our-story";
import Aibidata from "./component/technologycoes/ai-bi-data";
import IotMobilityDevices from "./component/technologycoes/iot-mobility-devices";
import Devops from "./component/technologycoes/devops";
import Cloud from "./component/technologycoes/cloud";
import OpenSourceTech from "./component/technologycoes/open-source-tech";
import MicrosoftTechnologies from "./component/technologycoes/microsoft-technologies";
import DigitalTransformation from "./component/services/DigitalTransformation";
import EnterpriseApplications from "./component/services/EnterpriseApplications";
import PlatformEngineering from "./component/services/PlatformEngineering";
import VerificationandValidation from "./component/services/verification-and-validation";
import LeadershipTeam from "./component/about/Leadership-Team";
import OurStory from "./component/about/our-story";
import LifeatSynoverge from "./component/about/lifeatsynoverge";
import WhySynoverge from "./component/about/whysynoverge";
import Career from "./component/career/career";
import Contactus from "./component/contactus/contact";
import Privacypolicy from "./component/privacypolicy/policy";
import Blog from "./component/blog/blog";
import Accrualscheme from "./component/blog/accrual-scheme";
import Datareconciliation from "./component/blog/data-reconciliation";
import Awsapigateway from "./component/blog/aws-api-gateway";
import Net6releaseupdates from "./component/blog/net-6-release-updates";
import Bestpracticesforlaas from "./component/blog/best-practices-for-laas-workloads-in-azure";
import Projectmethodologyagile from "./component/blog/project-methodology-agile";
import MicrosoftDynamicCopilot from "./component/blog/microsoftDynamicCopilot";
import UxRole from "./component/blog/uxrole";
import ExcitingNodejsUpdates from "./component/blog/excitingnodejsupdates";
import Globalrolloutsdynamicserps from "./component/blog/global-rollouts-dynamics-erps";
import Datapipeline from "./component/blog/data-pipeline";
import Softwarepartner from "./component/blog/how-to-Select-a-software-partner-that-delivers";
import Casestudies from "./component/casestudies/casestudies";
import Insurance from "./component/casestudies/Product-Platform/insurance";
import Surepathdigital from "./component/casestudies/Product-Platform/surepathdigital";
import Onlinerealtimepolicy from "./component/casestudies/Product-Platform/real-time-policy";
import Datacollectionplatform from "./component/casestudies/Product-Platform/data-collection-platform";
import Interviewmanagementplatform from "./component/casestudies/Product-Platform/interview-management-platform";
import Coursedeliverysystem from "./component/casestudies/Product-Platform/course-delivery-system";
import Screeningbackgroundcheckservices from "./component/casestudies/Product-Platform/screening-background-check-services";
import Integrationframework from "./component/casestudies/Product-Platform/integration-framework";
import Homewarrantyservices from "./component/casestudies/Enterprise-Applications/home-warranty-services";
import Homewarrantyservicessalesoptimus from "./component/casestudies/Enterprise-Applications/home-warranty-services-sales-optimus";
import Logisticsanddistribution from "./component/casestudies/Enterprise-Applications/logistics-and-distribution";
import Autoancillarymanufacturingcompany from "./component/casestudies/Enterprise-Applications/auto-ancillary-manufacturing-company";
import Nongovernmentalorganizations from "./component/casestudies/Enterprise-Applications/non-governmental-organizations";
import Printermanufacturer from "./component/casestudies/Enterprise-Applications/printer-manufacturer";
import Medicaldeviceserp from "./component/casestudies/Enterprise-Applications/medical-devices-erp";
import Wellnessservices from "./component/casestudies/Enterprise-Applications/wellness-services";
import Autoancillarymanufacturingcompanyindia from "./component/casestudies/Enterprise-Applications/auto-ancillary-manufacturing-company-india";
import Manufacturingadvancestextilesforautomotivesector2 from "./component/casestudies/Enterprise-Applications/manufacturing-advances-textiles-for-automotive-sector-2";
import Smartwaterfilters from "./component/casestudies/IOT-Mobility-Devices/smart-water-filters";
import Mobileecommercestore from "./component/casestudies/IOT-Mobility-Devices/mobile-ecommerce-store";
import Mortgagevendormanagement from "./component/casestudies/IOT-Mobility-Devices/mortgage-vendor-management";
import Healthandinsurance from "./component/casestudies/IOT-Mobility-Devices/health-and-insurance";
import Logisticsplatform from "./component/casestudies/IOT-Mobility-Devices/logistics-platform";
import Claimmanagementinsurance from "./component/casestudies/IOT-Mobility-Devices/claim-management-insurance";
import Selfvehicleinspection from "./component/casestudies/IOT-Mobility-Devices/self-vehicle-inspection";
import Connectedtocrop from "./component/casestudies/IOT-Mobility-Devices/connected-to-crop";
import Insurancesolution from "./component/casestudies/Microsoft-Technologies/insurance-solution";
import Centralestimation from "./component/casestudies/Microsoft-Technologies/central-estimation-platform-for-infrastructure-management-services";
import Unifiedservices from "./component/casestudies/Microsoft-Technologies/unified-services-platform-a-global-isv";
import Engineeringandtechnology from "./component/casestudies/Microsoft-Technologies/engineering-and-technology-solution-provider-that-provides-goods-and-services";
import Enterprisedevelopment from "./component/casestudies/Microsoft-Technologies/enterprise-development-and-online-training";
import Performancetesting from "./component/casestudies/Verification-Validation/performance-testing";
import Automationtesting from "./component/casestudies/Verification-Validation/automation-testing";
import Securitytesting from "./component/casestudies/Verification-Validation/security-testing";
import Testcasemanagement from "./component/casestudies/Verification-Validation/test-case-management";
import Collaborativeextranetproductsuite from "./component/casestudies/Open-Source-Technologies/collaborative-extranet-product-suite";
import ApplyCareer from "./component/career/applycareer";
import Financialservices from "./component/casestudies/Enterprise-Applications/financial-services";
import Constructionmanufacturing from "./component/casestudies/Enterprise-Applications/construction-and-manufacturing";
import Manufacturingchemicalproduction from "./component/casestudies/Enterprise-Applications/manufacturing-and-chemical-production";
import Manufacturing from "./component/casestudies/Enterprise-Applications/manufacturing";
import Retailtrading from "./component/casestudies/Enterprise-Applications/retail-and-trading";



const Letstalk = lazy(() => import(
  './component/letstalk/talk'))
const Footer = lazy(() => import(
  '../src/component/footer/footer'))

function App() {
  return (
    <Fragment>
      <Suspense fallback=
        {<div>Loading...</div>}>
        <ToastContainer />
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-story" element={<Ourstory />} />
            <Route path="/digital-transformation" element={<DigitalTransformation />} />
            <Route path="/enterprise-applications" element={<EnterpriseApplications />} />
            <Route path="/product-engineering-services" element={<PlatformEngineering />} />
            <Route path="/verification-and-validation" element={<VerificationandValidation />} />
            <Route path="/ai-bi-data" element={<Aibidata />} />
            <Route path="/iot-mobility-devices" element={<IotMobilityDevices />} />
            <Route path="/devops" element={<Devops />} />
            <Route path="/cloud" element={<Cloud />} />
            <Route path="/open-source-technologies" element={<OpenSourceTech />} />
            <Route path="/microsoft-technologies" element={<MicrosoftTechnologies />} />
            <Route path="/leadership-team" element={<LeadershipTeam />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/life-at-synoverge" element={<LifeatSynoverge />} />
            <Route path="/why-synoverge" element={<WhySynoverge />} />
            <Route path="/career" element={<Career />} />
            <Route path="/career/*" element={<ApplyCareer />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/accrual-scheme-in-microsoft-dynamics-365-fo" element={<Accrualscheme />} />
            <Route path="/blog/gstr-2a-data-reconciliation-with-books-of-accounts" element={<Datareconciliation />} />
            <Route path="/blog/aws-api-gateway" element={<Awsapigateway />} />
            <Route path="/blog/net-6-release-updates" element={<Net6releaseupdates />} />
            <Route path="/blog/best-practices-for-laas-workloads-in-azure" element={<Bestpracticesforlaas />} />
            <Route path="/blog/project-methodology-agile" element={<Projectmethodologyagile />} />
            <Route path="/blog/microsoftDynamicCopilot" element={<MicrosoftDynamicCopilot />} />
            <Route path="/blog/uxrole" element={<UxRole />} />
            <Route path="/blog/excitingnodejsupdates" element={<ExcitingNodejsUpdates />} />
            <Route path="/blog/global-rollouts-dynamics-erps" element={<Globalrolloutsdynamicserps />} />
            <Route path="/blog/data-pipeline" element={<Datapipeline />} />
            <Route path="/blog/how-to-Select-a-software-partner-that-delivers" element={<Softwarepartner />} />
            <Route path="/case-studies" element={<Casestudies />} />
            <Route path="/case-studies/insurance-e-bonding-process" element={<Insurance />} />
            <Route path="/case-studies/surepathdigital" element={<Surepathdigital />} />
            <Route path="/case-studies/online-real-time-policy-issuance-engine-portal" element={<Onlinerealtimepolicy />} />
            <Route path="/case-studies/data-collection-platform" element={<Datacollectionplatform />} />
            <Route path="/case-studies/interview-management-platform-2" element={<Interviewmanagementplatform />} />
            <Route path="/case-studies/course-delivery-system" element={<Coursedeliverysystem />} />
            <Route path="/case-studies/screening-background-check-services" element={<Screeningbackgroundcheckservices />} />
            <Route path="/case-studies/integration-framework" element={<Integrationframework />} />
            <Route path="/case-studies/home-warranty-services-fo" element={<Homewarrantyservices />} />
            <Route path="/case-studies/home-warranty-services-sales-optimus" element={<Homewarrantyservicessalesoptimus />} />
            <Route path="/case-studies/logistics-and-distribution" element={<Logisticsanddistribution />} />
            <Route path="/case-studies/auto-ancillary-manufacturing-company" element={<Autoancillarymanufacturingcompany />} />
            <Route path="/case-studies/non-governmental-organizations" element={<Nongovernmentalorganizations />} />
            <Route path="/case-studies/printer-manufacturer" element={<Printermanufacturer />} />
            <Route path="/case-studies/medical-devices-erp" element={<Medicaldeviceserp />} />
            <Route path="/case-studies/wellness-services" element={<Wellnessservices />} />
            <Route path="/case-studies/auto-ancillary-manufacturing-company-india" element={<Autoancillarymanufacturingcompanyindia />} />
            <Route path="/case-studies/manufacturing-advances-textiles-for-automotive-sector-2" element={<Manufacturingadvancestextilesforautomotivesector2 />} />
            <Route path="/case-studies/smart-water-filters" element={<Smartwaterfilters />} />
            <Route path="/case-studies/mobile-ecommerce-store" element={<Mobileecommercestore />} />
            <Route path="/case-studies/mortgage-vendor-management" element={<Mortgagevendormanagement />} />
            <Route path="/case-studies/health-and-insurance" element={<Healthandinsurance />} />
            <Route path="/case-studies/logistics-platform" element={<Logisticsplatform />} />
            <Route path="/case-studies/claim-management-insurance" element={<Claimmanagementinsurance />} />
            <Route path="/case-studies/self-vehicle-inspection" element={<Selfvehicleinspection />} />
            <Route path="/case-studies/connected-to-crop" element={<Connectedtocrop />} />
            <Route path="/case-studies/insurance-solution" element={<Insurancesolution />} />
            <Route path="/case-studies/central-estimation-platform-for-infrastructure-management-services" element={<Centralestimation />} />
            <Route path="/case-studies/unified-services-platform-a-global-isv" element={<Unifiedservices />} />
            <Route path="/case-studies/engineering-and-technology-solution-provider-that-provides-goods-and-services" element={<Engineeringandtechnology />} />
            <Route path="/case-studies/enterprise-development-and-online-training" element={<Enterprisedevelopment />} />
            <Route path="/case-studies/performance-testing" element={<Performancetesting />} />
            <Route path="/case-studies/automation-testing" element={<Automationtesting />} />
            <Route path="/case-studies/security-testing" element={<Securitytesting />} />
            <Route path="/case-studies/test-case-management" element={<Testcasemanagement />} />
            <Route path="/case-studies/collaborative-extranet-product-suite" element={<Collaborativeextranetproductsuite />} />
            <Route path="/case-studies/financial-services" element={<Financialservices/>} />
            <Route path="/case-studies/construction-and-manufacturing" element={<Constructionmanufacturing/>} />
            <Route path="/case-studies/manufacturing-and-chemical-production" element={<Manufacturingchemicalproduction/>} />
            <Route path="/case-studies/manufacturing" element={<Manufacturing/>} />
            <Route path="/case-studies/retail-and-trading" element={<Retailtrading />} />
            <Route path="/lets-talk" element={<Letstalk />} />
          </Routes>
          <Footer />
        </Router>
      </Suspense>
    </Fragment>
  );
}

export default App;

