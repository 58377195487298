import dotnet from "../../assets/images/ss.png";
import process from "../../assets/images/process.png";
import dm from "../../assets/images/dm.jpg";
import mba from "../../assets/images/mba.png";
import ax from "../../assets/images/ax.jpg";
export const careers = [
    {
        id:'Senior-Software-Engineer',
        jobPosition:'Software Specialist(.Net)',
        experience:'9-12 Years',
        positions:1,
        img: dotnet,
        BackEnd:[
            '.Net Core, MVC, Web API, WCF'
        ],
        FrontEnd:[
            'HTML5, Bootstrap 3/4, jQuery, Angular/ React'
        ],
        Database:[
            'SQL Server, Oracle'
        ],
        jobDescription:[
            'Manage team of 2- 5 engineers',
            'Software development as per project schedule',
            'Troubleshooting and debugging as required for bug fixing',
            'Source code version maintenance in version control software',
            'Unit testing of the application to ensure quality release to QA',
            'Adherence of coding standards while performing development',
            'Daily status reporting / progress updates / timesheets',
            'Prepare project specific documentation as required by project',
            'UAT / Implementation support as required by project',
            'Client interaction and communication as required by project',
            'Analysis and Design activities',
            'Software development as per project schedule',
            'Troubleshooting and Debugging as required for bug fixing',
            'Source code version maintenance in version control software',
            'Impact analysis of any changes requested by client',
            'Code review and peer testing to ensure coding standards are adhered on the project',
            'Daily status reporting / progress updates / timesheet',
            'Analysis and Design activities'
        ]
    },
    {
        id:'MBA-Finance',
        jobPosition:'D365 Functional Consultant-Finance',
        JobPositionSpanData:'(MBA, M. Com, Inter CA)' ,
        experience:'0-1 Year',
        positions:2,
        img: mba, 
        EducationalCriteria:[
            'MBA Finance, M.com, B.com, Inter CA.'
        ],            
        jobDescription:[
            'Must have strong basic knowledge of Accounting & Commercial principles (Debits & Credits, TB / P&L / BS, Purchase, Inventory, Sales & Distribution)',
            'Learn to navigate & set up Microsoft Dynamics ERP Suite (General Ledger, Budget, Fixed Asset, Taxation (GST), Account Payable, Account Receivable, Costing, Trade and Logistic, Inventory)',
            'Assist Business Process workshops in liaising with the client to understand their business requirements, study existing processes, and create functional requirements document',
            'Analysing business processes, determining gaps with the desired state of business, and suggest the right solutions and workarounds to client business teams',
            'Assist in functional workshops where the customer requirements will be analysed and translated from Business Requirements into Functional and Technical Requirements',
            'Prepare Business Analysis documentation (BRD, FRD, FDD, User Manuals documents etc.)',
            'Prepare Business Scenarios & Test Case Documents',
            'Microsoft Dynamics ERP Module configuration and setup',
            'Map the business scenarios in Microsoft Dynamics ERP',
            'Explain customizations to the technical team',
            'Carry out Functional Testing of the Module setups & customizations',
            'Assist in User Acceptance Testing (UAT)',
            'Provide Functional level training to Client end-users',
            'Handle change requests (CR) and maintain related documents',
            'Provide support to existing clients’ Dynamics ERP Applications and resolve issues',
            'Must be able to manage time, prioritize and communicate commitments',
            'Structured and analytical working approach to resolve critical problems',
            'Flexible attitude to the roles working hours',
            'Must have strong communication & presentation skills with willingness to work in highly collaborative teams ',
            'Willing to learn new applications & analytical tools'
        ],     
    },
    {
        id:'Digital-Marketing-Executive',
        jobPosition:'Digital Marketing Executive',
        experience:'4-7 Years',
        positions:1,
        img: dm,       
        jobDescription:[
            'Plan and execute all digital marketing, including SEO/SEM, marketing database, email, social media and display advertising campaigns',
            'Design, build and maintain our social media presence',
            'Build an inbound marketing plan including the content development and execution strategy',
            'Measure and report performance of all digital marketing campaigns, and assess against goals (ROI and KPIs)',
            'Research competitors and provide suggestions for improvement. Identify trends and insights, and optimize organic and paid performance based on the insights',
            'Brainstorm new and creative business growth strategies through digital channels.',
            'Plan, execute, and measure experiments and conversion tests',
            'Collaborate with internal teams to create landing pages and optimize user experience',
            'Utilize strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touch points',
            'Instrument conversion points and optimize user funnels',
            'Collaborate with agencies and other vendor partners for certain branding and marketing assignments',
            'Evaluate emerging technologies. Provide thought leadership and perspective for adoption where appropriate.'
        ],
        Requirementsandskills:[
            'Degree in marketing or a related field',
            'Proven working experience in digital marketing',
            'Demonstrable experience leading and managing SEO/SEM, marketing database, email, social media and/or display advertising campaigns'
        ]
    },
    {
        id:'Process-Lead',
        jobPosition:'Process Lead',
        experience:'4-7 Years',
        positions:1,
        img: process,       
        jobDescription:[
            'ISO 9001:2015 Quality Management System documentation',
            'ISO 27001 security management system documentation and implementation',
            'Planning & Conducting internal audits and track non compliances to closure',
            'Ensure ISO surveillance and recertification audits are completed successfully with no non-compliances',
            'Maintaining documentation and records as required by ISO standards & QMS',
            'Maintain measurements data and monitor against Management Quality Objectives',
            'Perform RCA to identify process improvement opportunities and recommend improvements',
            'Mentoring, motivating and training team on process compliance',
            'Organize Management Review meetings and create records'
        ],
        Expectations:[
            '4-6 years’ experience in ISO 9001 and 27001 with an',
            'Experience with CMMI will be added advantage',
            'ISO 9001 / 27001 Certified Auditor',
            'Good understanding of Software development life cycle & project execution methodologies'
        ],
        ExpectationsSpanData:'IT organization',
    }
]